import { useCallback, useRef, useEffect } from 'react';

const useDebounce = (callback, delay) => {
    const timerRef = useRef(null);

    const debounceCallback = useCallback((...args) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);

    // Cleanup function to clear the timeout on unmount or whenever callback or delay changes
    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [delay]);

    return debounceCallback;
};

export default useDebounce;
