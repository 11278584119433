import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-clock/dist/Clock.css";
import TimePickerPicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { DatePicker } from "rsuite";
import { isBefore } from "rsuite/esm/utils/dateUtils";
import * as Yup from "yup";
import {
  ADD_COURSE,
  EDIT_COURSE,
  GET_CATEGORY,
} from "../../../constant/apiEndPoint";
import {
  getRequest,
  patchFormRequest,
  postFormRequest,
} from "../../../helper/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";

import QuillEditor from "./QuillEditor";

const AddCourses = ({
  setAddEditModalShow,
  editCourseData,
  getTableCourse,
}) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [courseImagePreview, setCourseImagePreview] = useState(
    editCourseData?.courseImg || null
  );
  const [speakerImagePreview, setSpeakerImagePreview] = useState(
    editCourseData?.speakerImg || null
  );

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    courseImg: Yup.mixed().required("Course Image is required."),
    category: Yup.string().required("Category is required."),
    speakerImg: Yup.mixed().required("Speaker Image is required."),
    speakerName: Yup.string().required("Speaker Name is required."),
    description: Yup.string().required("Description is required."),
    price: Yup.number()
      .typeError("Price must be a number.")
      .required("Price is required."),
    lecture: Yup.number()
      .typeError("Lecture must be a number.")
      .required("Lecture is required."),
    activeStatus: Yup.string().required("Status is required."),
    isCertified: Yup.boolean(),
    isAvailable: Yup.boolean(),
    courseStartTime: Yup.string().required("Start Time is required."),
    courseEndTime: Yup.string().required("End Time is required."),
    courseStartDate: Yup.date().required("Start Date is required."),
    courseEndDate: Yup.date().required("End Date is required."),
  });

  const getCategoriesList = async () => {
    try {
      const res = await getRequest(GET_CATEGORY);
      setCategoriesList(res.data.data);
    } catch (error) {
      console.log("res====>", error);
    }
  };

  useEffect(() => {
    getCategoriesList();
  }, []);
  const initialValues = {
    name: editCourseData?.name || "",
    courseImg: editCourseData?.courseImg || null,
    category: editCourseData?.category || "",
    speakerImg: editCourseData?.speakerImg || null,
    speakerName: editCourseData?.speakerName || "",
    description: editCourseData?.description || "",
    price: editCourseData?.price || "",
    lecture: editCourseData?.lecture || "",
    activeStatus: editCourseData?.activeStatus || "",
    isCertified: editCourseData?.isCertified || false,
    isAvailable: editCourseData?.isAvailable || false,
    courseStartTime: editCourseData?.courseStartTime || "",
    courseEndTime: editCourseData?.courseEndTime || "",
    courseStartDate: editCourseData?.courseStartDate || new Date(),
    courseEndDate: editCourseData?.courseEndDate || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!editCourseData?._id) {
        try {
          const res = await postFormRequest(ADD_COURSE, values);
          if (res.type === 1) {
            successNotification(res?.response?.data?.message);
          }
          if (res.type === 2) {
            errorNotification(res.errormessage);
          }
        } catch (error) {
          console.log("res====>", error);
        } finally {
          getTableCourse();
        }
      } else {
        try {
          const res = await patchFormRequest(
            `${EDIT_COURSE}?id=${editCourseData?._id}`,
            values
          );
          console.log(res, "res");
          if (res.type === 1) {
            successNotification(res?.response?.data?.message);
          }
          if (res.type === 2) {
            errorNotification(res.errormessage);
          }
        } catch (error) {
          console.log("res====>", error);
        } finally {
          getTableCourse();
        }
      }
      setAddEditModalShow(false);
    },
  });

  // useEffect(() => {
  //   if (formik.values.activeStatus === "live") {
  //     formik.setFieldValue("courseStartDate", new Date());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik.values.activeStatus]);

  const handlecCourseImageChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue("courseImg", file);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setCourseImagePreview(previewUrl);
    }
  };

  const handlecSpeakerImageChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue("speakerImg", file);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setSpeakerImagePreview(previewUrl);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-0" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <div className="card-body p-1">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="name">
                        Name
                      </label>
                      <input
                        placeholder="Name"
                        id="name"
                        type="text"
                        className="form-control"
                        {...formik.getFieldProps("name")}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div style={{ color: "red" }}>{formik.errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group fallback w-100">
                      <label className="form-label d-block" htmlFor="courseImg">
                        Course Image
                      </label>
                      <span className="d-flex" style={{ columnGap: "10px" }}>
                        <input
                          id="courseImg"
                          type="file"
                          className="form-control"
                          onChange={handlecCourseImageChange}
                        />
                        {courseImagePreview && (
                          <div className="image-preview">
                            <img
                              src={courseImagePreview}
                              alt="Course Preview"
                              className="img-thumbnail-image"
                            />
                          </div>
                        )}
                      </span>
                      {formik.touched.courseImg && formik.errors.courseImg ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.courseImg}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="category">
                        Category
                      </label>
                      <select
                        className="form-select"
                        id="category"
                        {...formik.getFieldProps("category")}
                      >
                        <option value="" disabled>
                          Select category
                        </option>
                        {categoriesList?.map((data) => (
                          <option key={data._id} value={data._id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                      {formik.touched.category && formik.errors.category ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.category}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="price">
                        Price
                      </label>
                      <input
                        placeholder="Price"
                        id="price"
                        type="number"
                        className="form-control"
                        {...formik.getFieldProps("price")}
                      />
                      {formik.touched.price && formik.errors.price ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.price}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="speakerName">
                        Speaker Name
                      </label>
                      <input
                        placeholder="Speaker name"
                        id="speakerName"
                        type="text"
                        className="form-control"
                        {...formik.getFieldProps("speakerName")}
                      />
                      {formik.touched.speakerName &&
                        formik.errors.speakerName ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.speakerName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group fallback w-100">
                      <label
                        className="form-label d-block"
                        htmlFor="speakerImg"
                      >
                        Speaker Image
                      </label>
                      <span className="d-flex" style={{ columnGap: "10px" }}>
                        <input
                          id="speakerImg"
                          type="file"
                          className="form-control"
                          onChange={handlecSpeakerImageChange}
                        />
                        {speakerImagePreview && (
                          <div className="image-preview">
                            <img
                              src={speakerImagePreview}
                              alt="Speaker Preview"
                              className="img-thumbnail-image"
                            />
                          </div>
                        )}
                      </span>
                      {formik.touched.speakerImg && formik.errors.speakerImg ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.speakerImg}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="description">
                        Description
                      </label>
                      <QuillEditor formik={formik} />
                      {formik.touched.description && formik.errors.description ? (
                        <div style={{ color: "red" }}>{formik.errors.description}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="lecture">
                        Lecture
                      </label>
                      <input
                        placeholder="Lecture"
                        id="lecture"
                        type="text"
                        className="form-control"
                        {...formik.getFieldProps("lecture")}
                      />
                      {formik.touched.lecture && formik.errors.lecture ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.lecture}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="activeStatus">
                        Status
                      </label>
                      <select
                        className="form-select"
                        id="activeStatus"
                        {...formik.getFieldProps("activeStatus")}
                      >
                        <option value="" disabled>
                          Select status
                        </option>
                        <option value="live">Live</option>
                        <option value="upcoming">Upcoming</option>
                      </select>
                      {formik.touched.activeStatus &&
                        formik.errors.activeStatus ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.activeStatus}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isCertified"
                        {...formik.getFieldProps("isCertified")}
                        checked={formik.values.isCertified}
                      />
                      <label className="form-check-label" htmlFor="isCertified">
                        Certificate
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isAvailable"
                        {...formik.getFieldProps("isAvailable")}
                        checked={formik.values.isAvailable}
                      />
                      <label className="form-check-label" htmlFor="isAvailable">
                        Available
                      </label>
                    </div>
                  </div>
                  {formik.values.activeStatus && (
                    <>
                      <div className="col-lg-6 col-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="courseStartDate"
                          >
                            Start Date
                          </label>
                          <div className="input-hasicon mb-xl-0 mb-3">
                            <DatePicker
                              selected={formik.values.courseStartDate}
                              onChange={(date) =>
                                formik.setFieldValue("courseStartDate", date)
                              }
                              placeholderText="Start Date"
                              format="yyyy-MM-dd"
                              preventOverflow
                              className="picker-suit"
                              shouldDisableDate={(date) =>
                                isBefore(date, new Date().setHours(0, 0, 0, 0))
                              }
                              defaultValue={
                                editCourseData?.courseStartDate
                                  ? new Date(formik.values.courseStartDate)
                                  : new Date()
                              }
                              disabled={formik.values.activeStatus === "live"}
                            />
                            <div className="icon">
                              <i className="far fa-calendar" />
                            </div>
                          </div>
                          {formik.touched.courseStartDate &&
                            formik.errors.courseStartDate ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.courseStartDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="courseEndDate">
                            End Date
                          </label>
                          <div className="input-hasicon mb-xl-0 mb-3">
                            <DatePicker
                              size="md"
                              preventOverflow
                              shouldDisableDate={(date) =>
                                isBefore(date, new Date().setHours(0, 0, 0, 0))
                              }
                              format="yyyy-MM-dd"
                              selected={new Date(formik.values.courseEndDate)}
                              defaultValue={
                                editCourseData.courseEndDate
                                  ? new Date(formik.values.courseEndDate)
                                  : null
                              }
                              onChange={(date) =>
                                formik.setFieldValue("courseEndDate", date)
                              }
                              placeholderText="End Date"
                              className="picker-suit"
                            />
                            <div className="icon">
                              <i className="far fa-calendar" />
                            </div>
                            {formik.touched.courseEndDate &&
                              formik.errors.courseEndDate ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.courseEndDate}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="form-group picker-data">
                          <label
                            className="form-label"
                            htmlFor="courseStartTime"
                          >
                            Start Time
                          </label>
                          <div className="color-time-picker style-1">
                            <TimePickerPicker
                              selected={formik.values.courseStartTime}
                              name="courseStartTime"
                              onChange={(value) => {
                                formik.setFieldValue("courseStartTime", value);
                              }}
                              value={formik.values.courseStartTime}
                            />
                          </div>
                          {formik.touched.courseStartTime &&
                            formik.errors.courseStartTime ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.courseStartTime}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="form-group picker-data">
                          <label className="form-label" htmlFor="courseEndTime">
                            End Time
                          </label>
                          <div className="color-time-picker style-1">
                            <TimePickerPicker
                              name="courseEndTime"
                              onChange={(value) => {
                                formik.setFieldValue("courseEndTime", value);
                              }}
                              value={formik.values.courseEndTime}
                            />
                          </div>
                          {formik.touched.courseEndTime &&
                            formik.errors.courseEndTime ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.courseEndTime}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-12 text-end mt-3">
                    <button
                      type="button"
                      className="btn btn-danger light"
                      onClick={() => {
                        formik.handleReset();
                        setAddEditModalShow(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary ms-2">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCourses;
