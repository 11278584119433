import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Row, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DELETE_STUDENT, GET_STUDENT } from "../../../constant/apiEndPoint";
import { deleteRequest, getRequest } from "../../../helper/axiosClient";
import { formatDate } from "../../../helper/dateFormate";
import {
  errorNotification,
  successNotification
} from "../../../helper/notification";
import CustomModal from "../../components/CustomModal";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import useDebounce from "../../hook/useDebounce ";

const theadData = [
  { heading: "Id" },
  { heading: "Name" },
  { heading: "Email Address" },
  { heading: "Phone Number" },
  { heading: "Course Name" },
  { heading: "Created Date" },
  { heading: "Action" },
];

const AllStudent = () => {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [studentData, setStudentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [studentDeleteId, setStudentDeleteId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const handleShowModal = (id) => {
    setModalShow(true);
    setStudentDeleteId(id._id);
  };

  const getStudentList = async (search = "") => {
    setIsLoading(true);
    try {
      const params = { page: page, perPage: recordPerPage, search: search };
      const res = await getRequest(GET_STUDENT, params, navigate, dispatch);
      if (res.data.status === 1) {
        setStudentData(res.data.data);
        setTotalPage(res?.data.totalPage);
      }
    } catch (error) {
      console.log("res====>", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (studentDeleteId) {
      try {
        const res = await deleteRequest(
          `${DELETE_STUDENT}?id=${studentDeleteId}`
        );
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
          setActivePage(1);
          setPage(1);
        }
        if (res.type === 2) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.log("res====>", error);
      }
    }
    setModalShow(false);
    getStudentList();
  };

  const handlePageChange = (pageIndex) => {
    setActivePage(pageIndex);
    setPage(pageIndex);
  };

  const handlePerPageChange = (e) => {
    setRecordPerPage(e);
  };

  const debouncedSearch = useDebounce((value) => {
    setPage(1);
    getStudentList(value.trim());
  }, 700);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value.trim());
    debouncedSearch(value.trim());
  };

  useEffect(() => {
    getStudentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, recordPerPage]);

  return (
    <>
      <Row>
        <Tab.Container defaultActiveKey={"List"}>
          <div className="col-lg-12">
            <Tab.Content className="row tab-content">
              <Tab.Pane eventKey="List" className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">All Students List</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div
                        id="holidayList"
                        className="dataTables_wrapper no-footer"
                      >
                        <div className="justify-content-between d-sm-flex">
                          <div className="dataTables_length">
                            <label className="d-flex align-items-center">
                              Show
                              <Dropdown className="search-drop">
                                <Dropdown.Toggle
                                  as="div"
                                  className="search-drop-btn"
                                >
                                  {recordPerPage}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => handlePerPageChange(10)}
                                  >
                                    10
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handlePerPageChange(20)}
                                  >
                                    20
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handlePerPageChange(30)}
                                  >
                                    30
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              entries
                            </label>
                          </div>
                          <div className="dataTables_filter">
                            <label>
                              Search :{" "}
                              <input
                                type="search"
                                placeholder="Search student"
                                value={searchTerm}
                                onChange={handleSearchChange}
                              />
                            </label>
                          </div>
                        </div>
                        {isLoading ? (
                          <Loader />
                        ) : studentData.length !== 0 ? (
                          <>
                            <table
                              id="example4"
                              className="display dataTable no-footer w-100"
                            >
                              <thead>
                                <tr>
                                  {theadData.map((item, ind) => (
                                    <th key={ind}>{item.heading}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {studentData.map((data, ind) => {
                                  const courseNames = data.course && data.course.map((course) => course.name).join(", ");
                                  return (
                                    <tr key={ind}>
                                      <td style={{ textWrap: 'nowrap' }}>{data._id}</td>
                                      <td style={{ textWrap: 'nowrap' }}>
                                        <strong>{data.name}</strong>
                                      </td>
                                      <td style={{ textWrap: 'nowrap' }}>
                                        <strong>{data.email}</strong>
                                      </td>
                                      <td style={{ textWrap: 'nowrap' }}>{data.phoneNumber}</td>
                                      <td style={{ textWrap: 'nowrap' }}>{courseNames}</td>
                                      <td style={{ textWrap: 'nowrap' }}>{formatDate(data.createdAt)}</td>
                                      <td style={{ textWrap: 'nowrap' }}>
                                        <button
                                          className="btn btn-xs sharp btn-danger"
                                          onClick={() => handleShowModal(data)}
                                        >
                                          <i className="fa fa-trash" />
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}{" "}
                              </tbody>
                            </table>
                            <Pagination
                              totalPage={totalPage} // Replace with actual total number of pages
                              activePage={activePage}
                              recordPerPage={5} // Replace with actual number of records per page
                              onPageChange={handlePageChange}
                            />
                          </>
                        ) : (
                          <h4 className="text-center">No Student list found</h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
          <CustomModal
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setStudentDeleteId(null);
            }}
            size="md"
            content={
              <>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Confirm delete
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete Student?
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    onClick={() => setModalShow(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </Modal.Footer>
              </>
            }
          />
        </Tab.Container>
      </Row>
    </>
  );
};

export default AllStudent;
