import React from 'react';

const Pagination = ({totalPage, activePage, recordPerPage, onPageChange }) => {

    // Generate pagination numbers based on totalPage
    const paginationNumbers = Array.from({ length: totalPage }, (_, index) => index + 1);

    return (
        <div className='d-sm-flex text-center justify-content-end align-items-center mt-3'>
            <div className='dataTables_paginate paging_simple_numbers' id='example5_paginate'>
                <span
                    className={`paginate_button previous ${activePage === 1 ? 'disabled' : ''}`}
                    onClick={() => {
                        if (activePage > 1) onPageChange(activePage - 1);
                    }}
                >
                    Previous
                </span>
                <span>
                    {paginationNumbers.map((number, index) => (
                        <span
                            key={index}
                            className={`paginate_button ${activePage === index + 1? 'current' : ''}`}
                            onClick={() => onPageChange(index + 1)}
                        >
                            {number}
                        </span>
                    ))}
                </span>
                <span
                    className={`paginate_button next ${activePage === totalPage ? 'disabled' : ''}`}
                    onClick={() => {
                        if (activePage < totalPage) onPageChange(activePage + 1);
                    }}
                >
                    Next
                </span>
            </div>
        </div>
    );
};

export default Pagination;
