import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORY,
} from "../../../constant/apiEndPoint";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../../helper/axiosClient";
import { formatDate } from "../../../helper/dateFormate";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import CustomModal from "../../components/CustomModal";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import useDebounce from "../../hook/useDebounce ";
import { gridDataBlog } from "../staff/GridData";

const theadData = [
  { heading: "Id" },
  { heading: "Name" },
  { heading: "Created Date" },
  { heading: "Action" },
];

const AllCategories = () => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editCategorieData, setEditCategorieData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [addModalCategoti, addModalCategories] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const handleShowModal = (data) => {
    setModalShow(true);
    setEditCategorieData(data);
  };

  const initialValues = {
    name: editCategorieData?.name || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Category name is required."),
  });

  const onSubmit = async (values) => {
    if (!editCategorieData?._id) {
      try {
        const res = await postRequest(ADD_CATEGORY, values);
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
          handleCloseCategoriesModal();
          getCategoriesList();
        }
        if (res.type === 2) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.log("res====>", error);
      }
    } else {
      try {
        const res = await patchRequest(
          `${EDIT_CATEGORY}?id=${editCategorieData?._id}`,
          values
        );
        successNotification(res.data.message);
        handleCloseCategoriesModal();
        getCategoriesList();
      } catch (error) {
        console.log("res====>", error);
      }
    }
  };

  const handleDelete = async () => {
    if (editCategorieData?._id) {
      try {
        const res = await deleteRequest(
          `${DELETE_CATEGORY}?id=${editCategorieData?._id}`
        );
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
        }
        if (res.type === 2) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.log("res====>", error);
      }
    }
    setModalShow(false);
    getCategoriesList();
    setActivePage(1);
    setPage(1);
    setEditCategorieData("");
  };

  const getCategoriesList = async (search = "") => {
    setIsLoading(true);
    try {
      const res = await getRequest(`${GET_CATEGORY}?page=${page}&perPage=${recordPerPage}&search=${search}`);
      setCategoriesList(res.data.data);
      setTotalPage(res?.data.totalPage);
    } catch (error) {
      console.log("res====>", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, recordPerPage]);

  const handleEditCategorie = (data) => {
    addModalCategories(true);
    setEditCategorieData(data);
  };
  const handleCloseCategoriesModal = () => {
    addModalCategories(false);
    setEditCategorieData("");
  };

  const debouncedSearch = useDebounce((value) => {
    setPage(1);
    getCategoriesList(value.trim());
  }, 700);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value.trim());
    debouncedSearch(value.trim());
  };

  const handlePageChange = (pageIndex) => {
    setActivePage(pageIndex);
    setPage(pageIndex);
  };

  const handlePerPageChange = (e) => {
    setRecordPerPage(e);
  };

  return (
    <>
      <Row>
        <Tab.Container defaultActiveKey={"List"}>
          <div className="col-lg-12">
            <Tab.Content className="row tab-content">
              <Tab.Pane eventKey="List" className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">All Categories List</h4>
                    <Button
                      onClick={() => {
                        addModalCategories(true);
                        setSearchTerm("");
                      }}
                      className="btn btn-primary"
                    >
                      + Add Categorie
                    </Button>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div
                        id="holidayList"
                        className="dataTables_wrapper no-footer"
                      >
                        <div className="justify-content-between d-sm-flex">
                          <div className="dataTables_length">
                            <label className="d-flex align-items-center">
                              Show
                              <Dropdown className="search-drop">
                                <Dropdown.Toggle
                                  as="div"
                                  className="search-drop-btn"
                                >
                                  {recordPerPage}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => handlePerPageChange(10)}
                                  >
                                    10
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handlePerPageChange(20)}
                                  >
                                    20
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handlePerPageChange(30)}
                                  >
                                    30
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              entries
                            </label>
                          </div>
                          <div className="dataTables_filter">
                            <label>
                              Search :{" "}
                              <input
                                type="search"
                                placeholder="Search categorie"
                                value={searchTerm}
                                onChange={handleSearchChange}
                              />
                            </label>
                          </div>
                        </div>
                        {isLoading ? (
                          <Loader />
                        ) : categoriesList.length !== 0 ? (
                          <>
                            <table
                              id="example4"
                              className="display dataTable no-footer w-100"
                            >
                              <thead>
                                <tr>
                                  {theadData.map((item, ind) => (
                                    <th key={ind}>{item.heading}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {categoriesList.map((data, ind) => (
                                  <tr key={ind}>
                                    <td style={{ textWrap: 'nowrap' }}>{data._id}</td>
                                    <td style={{ textWrap: "nowrap" }}>
                                      <strong>{data.name}</strong>
                                    </td>
                                    <td style={{ textWrap: "nowrap" }}>
                                      {formatDate(data.createdAt)}
                                    </td>
                                    <td style={{ textWrap: "nowrap" }}>
                                      <button
                                        className="btn btn-xs sharp btn-primary me-1"
                                        onClick={() =>
                                          handleEditCategorie(data)
                                        }
                                      >
                                        <i class="fa fa-pencil"></i>
                                      </button>
                                      <button
                                        className="btn btn-xs sharp btn-danger"
                                        onClick={() => handleShowModal(data)}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Pagination
                              totalPage={totalPage} // Replace with actual total number of pages
                              activePage={activePage}
                              recordPerPage={5} // Replace with actual number of records per page
                              onPageChange={handlePageChange}
                            />
                          </>
                        ) : (
                          <h4 className="text-center">No categories found</h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Grid" className="col-lg-12">
                <div className="row">
                  {gridDataBlog.map((item, ind) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      key={ind}
                    >
                      <div className="card card-profile">
                        <div className="card-header justify-content-end pb-0 border-0">
                          <Dropdown>
                            <Dropdown.Toggle
                              as="button"
                              className="btn btn-link i-false"
                              type="button"
                            >
                              <span className="dropdown-dots fs--1"></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              align="end"
                              className="dropdown-menu dropdown-menu-right border py-0"
                            >
                              <div className="py-2">
                                <Link to={"#"} className="dropdown-item">
                                  Edit
                                </Link>
                                <Link
                                  to={"#"}
                                  className="dropdown-item text-danger"
                                >
                                  Delete
                                </Link>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="card-body pt-2">
                          <div className="text-center">
                            <div className="profile-photo">
                              <img
                                src={item.image}
                                width="100"
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                            <h3 className="mt-4 mb-1">{item.name}</h3>
                            <p className="text-muted">{item.subject}</p>
                            <ul className="list-group mb-3 list-group-flush">
                              {item.content.map((data, ind) => (
                                <li
                                  className="list-group-item px-0 d-flex justify-content-between"
                                  key={ind}
                                >
                                  <span className="mb-0">{data.title} :</span>
                                  <strong>{data.subtitle}</strong>
                                </li>
                              ))}
                            </ul>
                            <Link
                              to={"/about-student"}
                              className="btn btn-outline-primary btn-rounded mt-3 px-4"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
          <CustomModal
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setEditCategorieData("");
            }}
            size="md"
            content={
              <>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Confirm delete
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete Categorie?
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    onClick={() => setModalShow(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </Modal.Footer>
              </>
            }
          />
          <CustomModal
            show={addModalCategoti}
            onHide={() => handleCloseCategoriesModal()}
            size="md"
            content={
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <>
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                        {editCategorieData?._id
                          ? "Edit Category"
                          : " Add Category"}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <div className="form-group">
                          <label className="form-label" htmlFor="name">
                            Category name
                          </label>
                          <Field
                            placeholder="Enter category name"
                            id="name"
                            type="text"
                            name="name"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mt-4 text-end">
                          <button
                            type="button"
                            className="btn btn-danger light me-2"
                            onClick={() => handleCloseCategoriesModal()}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!formik.dirty}
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </Modal.Body>
                  </>
                )}
              </Formik>
            }
          />
        </Tab.Container>
      </Row>
    </>
  );
};

export default AllCategories;
