import React from 'react'

const Loader = ({ isFullScreen = false, className = "" }) => {
    return (
        <div id={isFullScreen ? "preloader" : ""}>
            <div className={`sk-three-bounce ${className}`}>
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
            </div>
        </div>
    )
}

export default Loader