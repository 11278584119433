import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill.css';

const QuillEditor = ({ formik }) => {
    return (
        <div>
            <ReactQuill
                value={formik.values.description}
                onChange={(value) => formik.setFieldValue("description", value)}
            />
        </div>
    );
};

export default QuillEditor;
